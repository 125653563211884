import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectUser } from '../redux/actions/actions';
import { PatchEditUser } from '../api';

const ICON_EDIT = require('../assets/picture/icon/pencil.svg');

const selectedBox = [];

class TableUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: true,
      checkboxValue: null,
      editedEmail: null,
      editedUsername: null,
      editedPassword: null,
      editedFullname: null,
      editedRole: null,
    };
  }

  checkBox= (e, index) => {
    const checkedBox = e.target.checked;
    if (checkedBox) {
      selectedBox.push(index);
    } else {
      // unchecked item
      selectedBox.forEach((item, indexs) => {
        if (index === item) {
          selectedBox.splice(indexs, 1);
        }
      });
    }
    this.props.selectUser(selectedBox);
  }

  selectUser=(index) => {
    const { listUser } = this.props;
    this.setState({
      selectedUser: index,
      editedEmail: listUser[index].email,
      editedFullname: listUser[index].fullname,
      editedUsername: listUser[index].username,
      editedRole: listUser[index].role,
      editedPassword: listUser[index].passwordRaw,
    });
  }

  saveUser=(index) => {
    const { listUser } = this.props;
    PatchEditUser({
      email: this.state.editedEmail,
      password: this.state.editedPassword,
      username: this.state.editedUsername,
      fullname: this.state.editedFullname,
      role: this.state.editedRole,
      expired: null,
    }, listUser[index]._id).then((response) => {
      setTimeout(window.location.reload(), 2000);
    });
  }


  render() {
    const {
      selectedUser, editedRole, editedEmail, editedFullname, editedPassword, editedUsername,
    } = this.state;
    const { listUser } = this.props;
    if (listUser) {
      return (
        <div style={{ overflow: 'auto' }}>
          <table className="table">
            <thead style={{ background: '#F5F6F7' }}>
              <tr>
                <th scope="col" />
                <th scope="col">Email</th>
                <th scope="col">Username</th>
                <th scope="col">Kata Sandi</th>
                <th scope="col">Nama Lengkap</th>
                <th scope="col">Posisi</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {
                listUser.map((item, index) => {
                  if (selectedUser !== index) {
                    return (
                      <tr>
                        <td><input type="checkbox" name="check" value={this.state.checkboxValue} onChange={(e) => this.checkBox(e, index)} /></td>
                        <td>{item.email}</td>
                        <td>{item.username}</td>
                        <td>{item.passwordRaw}</td>
                        <td>{item.fullname}</td>
                        <td>{item.role}</td>
                        <td>
                          <img style={{ cursor: 'pointer' }} alt="Edit" src={ICON_EDIT} onClick={() => this.selectUser(index)} />
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr>
                      <td><input type="checkbox" name="check" /></td>
                      <td><input type="text" className="box-user" value={editedEmail} onChange={(e) => this.setState({ editedEmail: e.target.value })} /></td>
                      <td><input type="text" className="box-user" value={editedUsername} onChange={(e) => this.setState({ editedUsername: e.target.value })} /></td>
                      <td><input type="text" className="box-user" value={editedPassword} onChange={(e) => this.setState({ editedPassword: e.target.value })} /></td>
                      <td><input type="text" className="box-user" value={editedFullname} onChange={(e) => this.setState({ editedFullname: e.target.value })} /></td>
                      <td>
                        <select
                          className="form-control"
                          id="inputRole"
                          onChange={(e) => this.setState({ editedRole: e.target.value })}
                        >
                          <option value="admin" selected={item.role === 'admin'}>admin</option>
                          <option value="member" selected={item.role === 'member'}>member</option>
                          <option value="guest" selected={item.role === 'guest'}>guest</option>
                        </select>
                      </td>
                      <td>
                        <button type="button" className="btn btn-success btn-sm" onClick={() => this.saveUser(index)}> Simpan</button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      );
    }
    return (<div className="loader" />);
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  selectUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableUser);
