import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Table from '../component/Table';
import AddUser from '../component/AddUser';
import { GetListUser, DeleteUser } from '../api';
import { popupAddUser } from '../redux/actions/actions';


class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      popupDeleteUser: false,
    };
  }

  // memberi nama di tab browser
  componentDidMount() {
    document.title = 'User Management';

    // Get List user
    GetListUser(((response) => {
      this.setState({
        listUser: response.data,
      });
    }
    ));
  }

  addUser=() => {
    this.props.popupAddUser(true);
  }

  deleteUser=() => {
    this.props.selectedUser.forEach((item, i) => {
      DeleteUser(this.state.listUser[item]._id).then((response) => {
        setTimeout(window.location.reload(), 2000);
      });
    });
    this.setState({ popupDeleteUser: false });
  }

  componentDidUpdate() {
    console.log(this.props.nowTime);
  }

  render() {
    const { listUser, popupDeleteUser } = this.state;
    return (
      <div className="bg-white container mt-5">
        <div className="col-md-12 col-lg-12">
          <div className="row justify-content-between mb-5">
            <h2>Manajemen User</h2>
            <div className="row mt-3">
              <button type="button" className="btn btn-primary mr-3 ml-3" style={{ maxWidth: '151px', height: '42px' }} onClick={this.addUser}>
              Tambah User
              </button>
              <button type="button" className="btn btn-outline-danger" style={{ maxWidth: '151px', height: '42px' }} onClick={() => this.setState({ popupDeleteUser: true })}>
              Delete User
              </button>
            </div>
          </div>
          <Table listUser={listUser} />
        </div>
        <Modal show={popupDeleteUser} onHide={this.handleShow} centered>
          <div style={{
            maxWidth: 500, height: 'auto', top: '50%', bottom: '50%',
          }}
          >
            <h4 className="text-darkGray bg-lightGray pt-3 pb-3 pl-3"><b>Delete User</b></h4>
            <h5 className="text-center text-darkGray pt-2 pr-2 pl-2"><b>Apakah anda yakin ingin menghapus user?</b></h5>
            <div className="row justify-content-around mt-5 mb-4 pl-1 pr-1">
              <button type="button" className="btn btn-danger" style={{ width: '150px', height: '40px' }} onClick={() => this.setState({ popupDeleteUser: false })}>
              Kembali
              </button>
              <button type="button" className="btn btn-outline-secondary" style={{ width: '150px', height: '40px' }} onClick={this.deleteUser}>
              Ya
              </button>
            </div>
          </div>
        </Modal>
        <AddUser />
      </div>
    );
  }
}


const mapDispatchToProps = {
  popupAddUser,
};

const mapStateToProps = (state) => ({
  selectedUser: state.selectedUser,
  nowTime: state.nowTime,
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(UserManagement);
