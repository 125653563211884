import React, { Component } from 'react';
import { connect } from 'react-redux';
import Maps from '../component/Maps';
import ChartArea from '../component/ChartArea';
import { GetChartData } from '../api';

// library for download csv
const fileDownload = require('js-file-download');

// timestamp for oneday and onehour
const ONEDAY = 24 * 3600 * 1000;
const ONEHOUR = 1 * 3600 * 1000;

let setTimeUnit = '24 jam terakhir';
let setTimestamp = new Date().getTime() - ONEDAY;
let setSatuan = 'hour';

let pagePath;
let paramUnit;
let paramName;

class Trend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChart: [],
    };
  }

  // memberi nama di tab browser
  componentDidMount() {
    // add name page
    document.title = 'Tren';

    pagePath = this.props.match.params.param;
    paramUnit = this.props.parameterProp ? this.props.parameterProp[pagePath].unit : null;
    paramName = this.props.parameterName[pagePath];

    // get data pertama kali
    this.updateData();

    // update data tiap jam
    setInterval(this.updateData, ONEHOUR);
  }

  updateData = () => {
    GetChartData({
      sensor: pagePath,
      'timestamp[$gte]': this.props.nowTime - ONEDAY,
    }).then((response) => {
      this.setState({
        dataChart: response.data,
      });
    });
  }

  changePage = () => {
    this.props.history.push(`snapshot-${this.props.match.params.param}`);
  }

  changeSatuan=(satuan) => {
    switch (satuan) {
      case 'hour':
        setSatuan = 'hour';
        break;
      default:
      case 'day':
        setSatuan = 'day';
        break;
      case 'month':
        setSatuan = 'month';
        break;
    }

    GetChartData({
      sensor: pagePath,
      'timestamp[$gte]': setTimestamp,
      unit: setSatuan,
    }).then((response) => {
      this.setState({
        dataChart: response.data,
      });
    });
  }

  changeInterval=(interval) => {
    const { nowTime } = this.props;
    setTimeUnit = interval;

    switch (interval) {
      case '24 jam terakhir':
        setTimestamp = nowTime - ONEDAY;
        break;
      default:
      case '7 hari terakhir':
        setTimestamp = nowTime - (ONEDAY * 7);
        break;
      case '30 hari terakhir':
        setTimestamp = nowTime - (ONEDAY * 30);
        break;
      case '12 bulan terakhir':
        setTimestamp = nowTime - (ONEDAY * 365);
        break;
    }

    GetChartData({
      sensor: pagePath,
      'timestamp[$gte]': setTimestamp,
      unit: setSatuan,
    }).then((response) => {
      this.setState({
        dataChart: response.data,
      });
    });
  }

  // untuk download data grafik dalam csv
  downloadCSV = () => {
    const type = pagePath;
    const { dataDevice } = this.props;
    const { data } = this.state.dataChart[0];
    const { data2 } = this.state.dataChart[1];
    const variable = `${paramName} (${this.props.parameterProp[type].unit})`;
    if (data) {
      const text = `"Tanggal","Waktu","Nama Parameter",${dataDevice[0].name}, ${dataDevice[1].name},${dataDevice[2].name},${dataDevice[3].name},${dataDevice[4].name}${data.map((item, index) => (`\n"${new Date(item.time).toLocaleDateString('en-GB')}","${new Date(item.time).toLocaleTimeString('en-GB')}",${variable}","${item.val}","${this.state.dataChart[1].data[index] ? this.state.dataChart[1].data[index].val : ''}","${this.state.dataChart[2].data[index] ? this.state.dataChart[2].data[index].val : ''}","${this.state.dataChart[3].data[index] ? this.state.dataChart[3].data[index].val : ''}","${this.state.dataChart[4].data[index] ? this.state.dataChart[4].data[index].val : ''}"`))}`;
      fileDownload(text, `${paramName}-${setTimeUnit}.csv`);
    }
  }


  render() {
    const { dataChart } = this.state;
    const { indexDevice, dataDevice } = this.props;
    if (dataChart.length !== 0) {
      return (
        <div className="row">
          <div className="col-md-3">
            <Maps />
          </div>
          <div className="col-md-9" style={{ paddingLeft: '30px', paddingRight: '30px' }}>

            <div className="row mt-4 mb-5">
              <h3 className="text-darkGray">
                {paramName}
                {' '}
                <span className="text-lowBlue">{dataDevice[indexDevice].name}</span>
              </h3>
              <div className="ml-auto">
                <button type="button" className="btn btn-outline-secondary pr-4 pl-4 mt-3 mt-auto mb-auto" style={{ width: '120px', height: '40px', borderRadius: '5px 0px 0px 5px' }} onClick={this.changePage}>
                      Snapshot
                </button>
                <button type="button" className="btn btn-lowBlue pr-4 pl-4 mt-3 mt-auto mb-auto active" style={{ width: '120px', height: '40px', borderRadius: '0px 5px 5px 0px' }}>
                      Tren
                </button>
                <button type="button" className="btn ml-3" onClick={this.downloadCSV}>
                  <img alt="unduh" src={require('../assets/picture/icon/download.svg')} />
                </button>
              </div>
            </div>

            <div className="card-chart">
              <div className="row justify-content-between mt-auto mb-auto">
                <h5 className="ml-5">
                    Tren
                  {' '}
                  {paramName}
                  <b>
                    {' '}
                    {setTimeUnit}
                  </b>
                </h5>
              </div>
              <div className="row mt-3 justify-content-end">
                <h5 className="mr-5"><b>Filter</b></h5>
                <div className="row mr-2 ml-2 mt-2 mb-2">
                  <div className="mr-2">Interval : </div>
                  <select
                    className="form-control"
                    style={{ width: 'auto' }}
                    onChange={(e) => this.changeInterval(e.target.value)}
                  >
                    <option value="24 jam terakhir">24 jam terakhir</option>
                    <option value="7 hari terakhir">7 hari terakhir</option>
                    <option value="30 hari terakhir">30 hari terakhir</option>
                    <option value="12 bulan terakhir">12 bulan terakhir</option>
                  </select>
                </div>

                <div className="row mr-2 ml-2 mt-2 mb-2">
                  <div className="mr-2">Satuan : </div>
                  <select
                    className="form-control"
                    style={{ width: 'auto' }}
                    onChange={(e) => this.changeSatuan(e.target.value)}
                  >
                    {
                    setTimeUnit === '24 jam terakhir' || setTimeUnit === '7 hari terakhir' || setTimeUnit === '30 hari terakhir'
                      ? <option value="hour">Per jam</option> : null
                    }

                    {
                    setTimeUnit === '30 hari terakhir' || setTimeUnit === '12 bulan terakhir' || setTimeUnit === '7 hari terakhir'
                      ? <option value="day">Per hari</option> : null
                    }

                    {
                    setTimeUnit === '12 bulan terakhir'
                      ? <option value="month">Per bulan</option> : null
                    }
                  </select>
                </div>
              </div>
              <ChartArea dataChart={dataChart[indexDevice] ? dataChart[indexDevice].data : []} heightChart={560} paramName={`${paramName} ${paramUnit}`} unitTime={setSatuan} unitInterval={setTimeUnit} />
            </div>

          </div>
        </div>
      );
    }
    return (<div className="loader" />);
  }
}

const mapDispatchToProps = {

};

const mapStateToProps = (state) => ({
  parameterProp: state.parameterProp,
  parameterName: state.parameterName,
  nowTime: state.nowTime,
  indexDevice: state.indexDevice,
  dataDevice: state.dataDevice,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(Trend);
