import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Compass from 'react-Compass';
// import 'react-compass/dist/react-compass.css';

const ICON_COMPASS = require('../assets/picture/icon/arrow-compass.svg');
const BACK_COMPASS = require('../assets/picture/icon/background-compass.svg');

class Compass extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { parameterProp, dataDevice, indexDevice } = this.props;
    return (
      <div className="row">
        <div className="col-lg-5 mr-3">
          <div className="compass-arrow" style={{ transform: `rotate(${dataDevice[indexDevice].latestData.data[3].val}deg)` }} />
          <div className="compass-back" />
        </div>
        <div className="col-lg-4 ml-5">
          <div className="bg-lightGray b-1 pt-2 pb-2 pl-3"><b>Keterangan</b></div>
          <div className="mb-2 ml-3 mr-3 mt-2">
            Arah angin saat ini:
            {' '}
            <b>{dataDevice[indexDevice].latestData.data[3].val}</b>
            {' '}
            {parameterProp.windd.unit}
          </div>
          <div style={{ borderBottom: 'solid 1px lightGray' }} />
          <div className="mb-2 ml-3 mr-3 mt-2">
            Prediksi 1 jam ke depan:
            {' '}
            <b>{dataDevice[indexDevice].latestData.data[3].val}</b>
            {' '}
            {parameterProp.windd.unit}
          </div>
          <div style={{ borderBottom: 'solid 1px lightGray' }} />
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = {

};

const mapStateToProps = (state) => ({
  parameterProp: state.parameterProp,
  dataDevice: state.dataDevice,
  indexDevice: state.indexDevice,
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(Compass);
