import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostLogin } from '../api';

// Logo List
const LOGO_PASANGKAYU = require('../assets/picture/logo-pasangkayu.png');
const LOGO_MARS = require('../assets/picture/logo-mars.svg');


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // memberi nama di tab browser
  componentDidMount() {
    document.title = 'Login';
  }

  pressEnter=(e) => {
    // login when press enter
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  // submit email dan password
  handleSubmit = () => {
    // copy email dan password ke variabel
    const username = document.getElementById('usernameInput').value;
    const password = document.getElementById('sandiInput').value;

    PostLogin({
      username,
      password,
      strategy: 'local',
    }).then((response) => {
      if (response.data.accessToken) {
        // muncul toast
        toast.success('Login Success', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });

        // menyimpan token dalam localStorage
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('role', response.data.user.role);

        // push login in login page
        setTimeout(this.props.history.push('overview'), 1500);
      }
    }).catch((error) => {
      // muncul toast salah username/password
      toast.error('Username atau Password salah', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
    });
  }

  render() {
    return (
      <div className="login">
        <div className="dark row">
          <div className="col-lg-7">
            <div className="web-name">
              <div className="mt-3">
                <div className="row justify-content-center">
                  <img style={{ height: '5rem' }} alt="Logo Pasangkayu" src={LOGO_PASANGKAYU} />
                  <div className="title ml-4 mt-auto mb-auto">Kabupaten Pasangkayu</div>
                </div>
              </div>

              <div className="mt-5 title text-center">
                <div><b>Sistem Monitoring Iklim dan Cuaca</b></div>
                <div><b>Kabupaten Pasangkayu</b></div>
                <div><b>Provinsi Sulawesi Barat</b></div>
              </div>

            </div>
          </div>

          <div className="col-lg-5">
            <div className="bg-white box">
              <div className="text-center mb-5" style={{ fontSize: '2rem', lineHeight: '3rem' }}> Masukkan ID dan Password </div>
              <form>
                <div className="form-group mt-3">
                  <label htmlFor="emailInput">Username</label>
                  <input type="text" className="form-control" id="usernameInput" onKeyPress={this.pressEnter} />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="sandiInput">Kata Sandi</label>
                  <input type="password" className="form-control" id="sandiInput" onKeyPress={this.pressEnter} />
                </div>

                <button type="button" className="btn btn-primary pr-4 pl-4 mt-3" onClick={this.handleSubmit}>
                  Masuk
                </button>
              </form>
            </div>
          </div>
          {
            window.screen.width > 500
              ? (
                <div
                  className="text-white col-lg-12"
                  style={{
                    bottom: '26px', top: '50x', marginLeft: '100px', position: 'absolute',
                  }}
                >
                  <h6>Dinas Kominfo Persandian dan Statistik Kabupaten Pasangkayu</h6>
                  <h6>Copyright © Pemerintah Kabupaten Pasangkayu</h6>
                  <h6>
                powered by
                    {' '}
                    {' '}
                    <a href="http://marsdigital.id/" className="text-white">
                      <b>Mars Indonesia Digital</b>
                    </a>
                  </h6>
                  <img height="50px" alt="Logo Mars" src={LOGO_MARS} />
                </div>
              )
              : null
          }

        </div>
        <ToastContainer />
      </div>
    );
  }
}
