import React from 'react';
import './App.css';
import './assets/style/default.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Overview from './page/Overview';
import Header from './header/Header';
import Login from './page/Login';
import NotFound from './page/NotFound';
import Trend from './page/Trend';
import Snapshot from './page/Snapshot';
import UserManagement from './page/UserManagement';

// List Page
const RoutePage = (props) => {
  if (localStorage.accessToken) {
    return (
      <div>
        {
          props.match.url !== '/login'
            ? <Header /> : ''
        }
        <Switch>
          <Route exact path="/overview" component={Overview} />
          <Route exact path="/tren-:param" component={Trend} />
          <Route exact path="/snapshot-:param" component={Snapshot} />
          <Route exact path="/user-management" component={UserManagement} />
          <Route exact path={props.match.url} component={Overview} />
          <Route exact path="/404" component={NotFound} />
          <Route
            exact
            path="/logout"
            component={(prop) => {
              localStorage.clear();
              prop.history.push('/');
              return ('Redirect...');
            }}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>

    );
  }

  // Page saat Logout
  return (
    <div>
      <Route path="/" component={Login} />
    </div>
  );
};

const App = () => (
  <Router>
    <Route component={RoutePage} />
  </Router>
);


export default App;
