import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Compass from 'react-Compass';
// import 'react-compass/dist/react-compass.css';

const ICON_COMPASS = require('../assets/picture/icon/arrow-compass.svg');
const BACK_COMPASS = require('../assets/picture/icon/background-compass.svg');

class Compass extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <div className="text-center not-found">
        <div className="align-items-center">
          <h1 className="text-lowBlue mb-3"><b>404</b></h1>
          <h4 className="mt-4">Halaman tidak ditemukan</h4>
          <div className="text-muted mb-4">
            Halaman mungkin bermasalah
            <br />
            atau sedang dalam
            <br />
            perbaikan
          </div>
          <button type="button" className="btn p-2 btn-primary mr-2" onClick={() => this.props.history.push('overview')}>
             Kembali ke halaman utama
          </button>
        </div>
      </div>
    );
  }
}


export default Compass;
