export const POPUP_ADD_USER = 'POPUP_ADD_USER';
export const ADD_PARAMETER_PROP = 'ADD_PARAMETER_PROP';
export const ADD_DEVICE_DATA = 'ADD_DEVICE_DATA';
export const SET_INDEX_DEVICE = 'SET_INDEX_DEVICE';
export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_LIST_VALUE = 'UPDATE_LIST_VALUE';
export const SELECT_USER = 'SELECT_USER';

export function popupAddUser(showStatus) {
  return { type: POPUP_ADD_USER, content: showStatus };
}

export function addParameterProp(properties) {
  return { type: ADD_PARAMETER_PROP, content: properties };
}

export function addDeviceData(data) {
  return { type: ADD_DEVICE_DATA, content: data };
}

export function setIndexDevice(index) {
  return { type: SET_INDEX_DEVICE, content: index };
}

export function updateTime(time) {
  return { type: UPDATE_TIME, content: time };
}

export function updateListValue(value) {
  return { type: UPDATE_LIST_VALUE, content: value };
}

export function selectUser(value) {
  return { type: SELECT_USER, content: value };
}
