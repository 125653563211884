import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { PostAddUser } from '../api';
import { popupAddUser } from '../redux/actions/actions';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  handleShow = () => {
    this.props.showAddUser(false);
  }

register =(e) => {
  e.preventDefault();

  PostAddUser({
    email: document.getElementById('inputEmail').value,
    username: document.getElementById('inputUsername').value,
    password: document.getElementById('inputSandi').value,
    fullname: document.getElementById('inputNama').value,
    role: document.getElementById('inputRole').value,
    expired: null,
  }).then(() => {
    setTimeout(window.location.reload(), 2000);
  });

  this.handleShow();
}

render() {
  const { popupAddUser } = this.props;
  return (
    <Modal show={popupAddUser} onHide={this.handleShow}>
      <form className="p-4">
        <h4 className="text-center mb-3">Registrasi Pengguna Baru</h4>
        <div className="form-group">
          <label>Email</label>
          <input type="email" className="form-control" id="inputEmail" required />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input type="text" className="form-control" id="inputUsername" required />
        </div>
        <div className="form-group">
          <label>Kata Sandi</label>
          <input type="text" className="form-control" id="inputSandi" required />
        </div>
        <div className="form-group">
          <label>Nama Lengkap</label>
          <input type="text" className="form-control" id="inputNama" required />
        </div>
        <div className="form-group">
          <label>Posisi</label>
          <select
            className="form-control"
            id="inputRole"
          >
            <option value="admin">Admin</option>
            <option value="member" selected>Member</option>
            <option value="guest">Guest</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary mt-3" onClick={(e) => this.register(e)}>Daftarkan</button>
      </form>
    </Modal>
  );
}
}

const mapStateToProps = (state) => ({
  popupAddUser: state.popupAddUser,
});

const mapDispatchToProps = {
  showAddUser: popupAddUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
