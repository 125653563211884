import React, { Component } from 'react';
import Gauge from 'react-svg-gauge';
import { withRouter } from 'react-router-dom';

const ICON_SENSOR = (type) => require(`../assets/picture/icon/${type}.svg`);

function getHexColor(value: number) {
  const string = value.toString(16);
  return (string.length === 1) ? `0${string}` : string;
}


class CardSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  changePage=() => {
    this.props.history.push(`snapshot-${this.props.type}`);
  }


  render() {
    const {
      bg, value, value2, type, parameter, properties, properties2, valuePredict,
    } = this.props;

    return (
      <div>
        <div style={{ backgroundColor: bg }} className="pt-1 card-sensor">
          <div className="bg-white p-3 text-center h-100">
            <div className="row ml-2" style={{ marginBottom: '-1rem' }}>
              <img alt="Icon sensor" src={ICON_SENSOR(type)} />
              <h5 className="mt-3 ml-3 mb-auto mt-auto text-darkGray"><b>{parameter}</b></h5>
            </div>

            <Gauge
              value={Math.floor(value)}
              min={properties ? properties.visual.v1 : null}
              max={properties ? properties.visual.v2 : null}
              width={230}
              color="#4FC3E9"
              height={160}
              valueLabelStyle={{ fontSize: '2rem', color: '#414B5A' }}
              label=""
            />

            <h4 className="text-darkGray" style={{ marginTop: '-1.5rem' }}>{properties ? properties.unit : null}</h4>
            <h6 className="text-darkGray">
              <span style={{ color: '#979CA4' }}>Prediksi 1 jam ke depan : </span>
              <b>
                {' '}
                {Math.floor(value)}
                {' '}
                {properties ? properties.unit : ''}
              </b>
            </h6>
            {
                    type === 'wind'
                      ? (
                        <div className="row justify-content-around mt-4">
                          <div className="text-gray">
                            <h6 className="text-left">
                              {'  '}
                              Arah:
                              {'  '}
                              {value2}
                              { properties2 ? properties2.unit : null}
                            </h6>
                            <h6>
                              {' '}
                              Prediksi:
                              {' '}
                              {Math.floor(value2)}
                              { properties2 ? properties2.unit : null}
                            </h6>
                          </div>
                          <div className="mt-auto mb-auto row">
                            <button type="button" className="btn p-2 btn-outline-primary mr-2" onClick={() => this.props.history.push('snapshot-windd')}>
                              Arah Angin
                            </button>
                            <button type="button" className="btn p-2 btn-outline-primary mr-2" onClick={() => this.props.history.push('snapshot-winds')}>
                             Kecepatan Angin
                            </button>
                          </div>
                        </div>
                      )
                      : (
                        <div className="text-right mt-4">
                          <button type="button" className="btn  btn-outline-primary ml-auto mt-2" onClick={this.changePage}>
                          Detail
                            {' '}
                            {parameter}
                            {' '}
                          &rarr;
                          </button>
                        </div>
                      )
                  }
          </div>
        </div>
      </div>

    );
  }
}

export default withRouter(CardSensor);
