import React, { Component } from 'react';
import {
  AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine,
} from 'recharts';

const Tgl = new Date();

// untuk tooltip
const stampTooltip = (item) => {
  Tgl.setTime(item);
  return Tgl.toLocaleString('en-GB');
};


// Bentuk Grafik
export default class ChartLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data dummy
    };
  }


  stamp2string = (item) => {
    Tgl.setTime(item);
    let axis;

    if (this.props.page !== 'snapshot' && this.props.unitInterval !== '24 jam terakhir') {
      axis = `${Tgl.toLocaleDateString('en-GB')}`;
    } else {
      axis = Tgl.toLocaleTimeString('en-GB');
      axis.slice(0, -2);
    }
    return axis;
  };

  // Setting Sumbu grafik
  CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          style={{
            fontSize: '.8em',
          }}
          textAnchor="end"
          fill="#666"
          transform="rotate(-0)"
        >
          {this.stamp2string(payload.value)}
        </text>
      </g>
    );
  };


  render() {
    const {
      dataChart, heightChart, t1, t2, paramName,
    } = this.props;
    if (true) {
      return (
        <div style={{ height: heightChart }}>
          <ResponsiveContainer>
            <AreaChart
              data={dataChart}
              margin={{
                top: 50, right: 50, left: -10, bottom: 50,
              }}
            >
              <XAxis dataKey="time" tick={this.CustomizedAxisTick} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Legend />
              <Tooltip labelFormatter={stampTooltip} />
              <ReferenceLine y={t2} label="Maks" stroke="red" strokeDasharray="3 3" strokeWidth={2} />
              <ReferenceLine y={t1} label="Min" stroke="red" strokeDasharray="3 3" strokeWidth={2} />
              <Area name={paramName} type="monotone" dataKey="val" stroke="#4FC3E9" fill="#4FC3E9" strokeWidth={2} />
            </AreaChart>
          </ResponsiveContainer>
        </div>

      );
    }

    return (
      <h4 className="text-center text-lowBlue" style={{ height: '300px', lineHeight: '300px' }}> Prediksi Belum Tersedia </h4>
    );
  }
}
