import React, { Component } from 'react';
import Gauge from 'react-svg-gauge';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Maps from '../component/Maps';
import ChartArea from '../component/ChartArea';
import Compass from '../component/Compass';
import { GetSnapshot, GetChartData } from '../api';
import { addParameterProp } from '../redux/actions/actions';
// import parsingResponse from '../function/parsing';

const titleSnapshot = ['Hari ini', 'Minggu ini', 'Bulan ini', 'Tahun ini'];
const titleDay = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

let pagePath;
let paramUnit;
let paramName;

// const arrayData = [[], [], [], [], []];

// one day and one hour timestamp
const ONEDAY = 24 * 3600 * 1000;
const ONEHOUR = 1 * 3600 * 1000;
const timestampNow = Math.floor(Date.now());


// check screen
const isMobile = window.screen.width < '500';

class Snapshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChart: [[], [], [], []],
      dataSnapshot: [],
    };
  }


  // memberi nama di tab browser
  componentDidMount() {
    document.title = 'Snapshot';

    pagePath = this.props.match.params.param;
    paramUnit = this.props.parameterProp ? this.props.parameterProp[pagePath].unit : null;
    paramName = this.props.parameterName[pagePath];

    /*
    // route to 404 if param not found
    const paramSensor = ['temp', 'hum', 'windd', 'winds', 'rain', 'press', 'solar'];
    let matchParam = true;
    paramSensor.forEach((item, index) => {
      if (item !== pagePath)
      { mat}
    });
    */

    // get data pertama kali
    this.updateData();

    // update data tiap jam
    setInterval(this.updateData, ONEHOUR);
  }

  updateData = () => {
    // get data
    GetChartData({
      sensor: pagePath,
      'timestamp[$gte]': (timestampNow - ONEDAY) + (1 * ONEHOUR),
      'timestamp[$lte]': (timestampNow - ONEDAY) + (12 * ONEHOUR),
    }).then((response) => {
      this.setState({
        dataChart: response.data,
      });
    });


    GetSnapshot({
      sensor: pagePath,
    }).then((response) => {
      this.setState({
        dataSnapshot: response.data,
      });
    });
  }

  changePage = () => {
    this.props.history.push(`tren-${pagePath}`);
  }

  setIndexParam = (pagePath) => {
    switch (pagePath) {
      case 'temp':
        return 0;

      case 'hum':
        return 1;

      case 'press':
        return 2;

      case 'windd':
        return 3;

      case 'winds':
        return 4;

      case 'solar':
        return 5;

      case 'rain':
        return 6;

      default:
        return 0;
    }
  }


  render() {
    const { dataChart, dataSnapshot } = this.state;
    const {
      indexDevice, listValue, nowTime, dataDevice, parameterProp,
    } = this.props;

    if (dataSnapshot.length !== 0 && dataDevice.length !== 0) {
      return (
        <div className="row bg-white">
          <div className="col-md-3">
            <Maps />
          </div>

          <div className="col-md-9" style={{ paddingLeft: '40px', paddingRight: '40px' }}>

            <div className="row mt-4 mb-4">
              <h3 className="text-darkGray">
                {paramName}
                {' '}
                <span className="text-lowBlue">{dataDevice[indexDevice].name}</span>
              </h3>
              <div className="ml-auto">
                <button type="button" className="btn btn-lowBlue pr-4 pl-4 mt-3 mt-auto mb-auto active" style={{ width: '120px', height: '40px', borderRadius: '5px 0px 0px 5px' }}>
                      Snapshot
                </button>
                <button type="button" className="btn btn-outline-secondary pr-4 pl-4 mt-3 mt-auto mb-auto" style={{ width: '120px', height: '40px', borderRadius: '0px 5px 5px 0px' }} onClick={this.changePage}>
                      Tren
                </button>
              </div>
            </div>
            {
              pagePath !== 'windd'
                ? (
                  <div>
                    <div className="row card-snapshot pb-5 pt-5">
                      <div className="col-md-3 mt-auto mb-auto">
                        <div className="row justify-content-center">
                          <div><img alt="clock" src={require('../assets/picture/icon/clock.svg')} /></div>
                          <h3 className="ml-2 text-darkGray">
                            {' '}
                            {' '}
                            {(`0${new Date(nowTime).getHours()}`).slice(-2)}
                        :
                            {(`0${new Date(nowTime).getMinutes()}`).slice(-2)}
                          </h3>
                        </div>
                        <div className="text-center">
                          <Gauge
                            value={dataDevice[indexDevice].latestData.data[this.setIndexParam(pagePath)].val}
                            min={parameterProp[pagePath].visual.v1}
                            max={parameterProp[pagePath].visual.v2}
                            width={250}
                            color="#4FC3E9"
                            height={150}
                            valueLabelStyle={{ fontSize: '30px' }}
                            label=""
                          />
                          <h5 className="text-darkGray" style={{ marginTop: '-1.5rem' }}>{paramUnit}</h5>
                          <h5 className="text-darkGray mb-3">{paramName}</h5>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h5>
                      Prakiraan
                          {' '}
                          {paramName}
                          {' '}
                      12 jam ke depan
                        </h5>
                        <ChartArea page="snapshot" unitInterval="24 jam terakhir" unitTime="hour" dataChart={dataChart[indexDevice] ? dataChart[indexDevice].data : []} heightChart={300} t1={null} t2={null} paramName={`${paramName} ${paramUnit}`} />
                        <h5 style={{ marginTop: '-30px' }}>
                      Prakiraan
                          {' '}
                          {paramName}
                          {' '}
                      6 hari ke depan
                        </h5>
                        <div
                          className="row text-center pt-3 pb-3"
                          style={{
                            marginRight: '20px', marginLeft: '20px', background: 'rgb(79, 195, 233, 0.12)',
                          }}
                        >
                          {
                            titleDay.map((item, i) => {
                              const dayIndex = new Date().getDay();
                              if (dayIndex !== i) {
                                return (
                                  <div className="col-md">
                                    <h5 className="text-lowBlue mb-3">{dayIndex + i + 1 < 7 ? titleDay[dayIndex + i + 1] : titleDay[dayIndex + i + -6]}</h5>
                                    <h5>
                                      <b>
                                        {Math.floor(dataSnapshot[indexDevice].summary.today.min)}
                                        {' '}
-
                                        {' '}
                                        {Math.floor(dataSnapshot[indexDevice].summary.today.max)}
                                      </b>
                                      <small className="ml-1 text-gray">{paramUnit}</small>
                                    </h5>
                                  </div>
                                );
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>

                    <div className="card-snapshot mt-3 pt-2 pb-2 row text-center">
                      {
                      [dataSnapshot[indexDevice].summary.today, dataSnapshot[indexDevice].summary.thisWeek,
                        dataSnapshot[indexDevice].summary.thisMonth, dataSnapshot[indexDevice].summary.thisYear].map((item, i) => (
                          <div className="col-md col-lg mr-2 ml-2 pt-3 pb-3 justify-content-center row">
                            <div>
                              <h5 className="mb-4">{titleSnapshot[i]}</h5>
                              <div className="row mt-3 pl-3 pr-3">
                                <div className="">Rata-Rata</div>
                                <div className="ml-4">
                                  <b>
                                    {Math.floor(item.mean)}
                                    {' '}
                                    {paramUnit}
                                  </b>
                                </div>
                              </div>

                              <div className="row mt-3 pl-3 pr-3">
                                <div className="">Minimum</div>
                                <div className="ml-auto">
                                  <b>
                                    {Math.floor(item.min)}
                                    {' '}
                                    {paramUnit}
                                  </b>
                                </div>
                              </div>

                              <div className="row mt-3 pl-3 pr-3 ">
                                <div className="">Maksimum</div>
                                <div className="ml-auto">
                                  <b>
                                    {Math.floor(item.max)}
                                    {' '}
                                    {paramUnit}
                                  </b>
                                </div>
                              </div>
                            </div>
                            {i < 3 && isMobile === false
                              ? <div style={{ borderRight: 'solid 1px lightGray', paddingRight: '5rem' }} />
                              : <div />}

                          </div>
                      ))
                    }
                    </div>
                  </div>
                )
                : <div><Compass /></div>
            }

          </div>
        </div>
      );
    }
    return (<div className="loader" />);
  }
}


const mapDispatchToProps = {
  addParameterProp,
};

const mapStateToProps = (state) => ({
  parameterProp: state.parameterProp,
  parameterName: state.parameterName,
  nowTime: state.nowTime,
  listValue: state.listValue,
  indexDevice: state.indexDevice,
  dataDevice: state.dataDevice,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(Snapshot);

Snapshot.propTypes = {
  parameterProp: PropTypes.object.isRequired,
};
