import React, { Component } from 'react';
import { connect } from 'react-redux';
import Maps from '../component/Maps';
import CardSensor from '../component/CardSensor';
import { GetDevices } from '../api';
import {
  addParameterProp, addDeviceData, setIndexDevice, updateTime, updateListValue,
} from '../redux/actions/actions';

// index each parameter
const indexTemp = 0;
const indexHum = 1;
const indexPress = 2;
const indexWindd = 3;
const indexWinds = 4;
const indexSolar = 5;
const indexRain = 6;

// cycle map every 20 seconds
const CYCLE_MAP = 20000;
// update data device every 10 minutes
const CYCLE_UPDATE_DATA = 600000;

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listValue: {
        temp: 0,
        hum: 0,
        press: 0,
        rain: 0,
        winds: 0,
        windd: 0,
        solar: 0,
      },
    };
  }

  // memberi nama di tab browser
  componentDidMount() {
    document.title = 'Overview';

    setInterval(this.updateData, CYCLE_UPDATE_DATA);

    // update timestamp every 1 seconds
    setInterval(this.updateTime, 1000);
  }

  updateTime=() => {
    this.props.updateTime(new Date().getTime());
  }

  updateData=() => {
    // REST GET Devices (avoiding crash when refresh in every page)
    GetDevices(((response) => {
      const devices = response.data;

      const parameterProp = {
        temp: devices[0].parameterProp[0],
        hum: devices[0].parameterProp[1],
        press: devices[0].parameterProp[2],
        windd: devices[0].parameterProp[3],
        winds: devices[0].parameterProp[4],
        solar: devices[0].parameterProp[5],
        rain: devices[0].parameterProp[6],
      };

      // store parameter properties in redux
      this.props.addParameterProp(parameterProp);

      // store device data in redux
      this.props.addDeviceData(devices);
    }
    ));
  }

  render() {
    const { listValue } = this.state;
    const { indexDevice, dataDevice, parameterProp } = this.props;
    if (dataDevice.length !== 0 && parameterProp) {
      return (
        <div className="row">
          <div className="col-lg-3">
            <Maps />
          </div>
          <div className="col-lg-9">
            <div className="row justify-content-around" style={{ marginTop: '-20px' }}>
              <CardSensor bg="#D84910" parameter="Kecepatan dan Arah Angin" value={dataDevice[indexDevice].latestData.data[indexWinds].val} value2={dataDevice[indexDevice].latestData.data[indexWindd].val} properties={parameterProp.winds} properties2={parameterProp.windd} type="wind" />
              <CardSensor bg="#1484FF" parameter="Suhu" value={dataDevice[indexDevice].latestData.data[indexTemp].val} properties={parameterProp.temp} type="temp" />
              <CardSensor bg="#4FC3E9" parameter="Kelembaban" value={dataDevice[indexDevice].latestData.data[indexHum].val} properties={parameterProp.hum} type="hum" />
              <CardSensor bg="#9B51E0" parameter="Tekanan Udara" value={dataDevice[indexDevice].latestData.data[indexPress].val} properties={parameterProp.press} type="press" />
              <CardSensor bg="#F0C452" parameter="Radiasi Matahari" value={dataDevice[indexDevice].latestData.data[indexSolar].val} properties={parameterProp.solar} type="solar" />
              <CardSensor bg="#F2994A" parameter="Curah Hujan" value={dataDevice[indexDevice].latestData.data[indexRain].val} properties={parameterProp.rain} type="rain" />
            </div>
          </div>
        </div>
      );
    }
    return (<div className="loader" />);
  }
}

const mapDispatchToProps = {
  addParameterProp,
  addDeviceData,
  setIndexDevice,
  updateTime,
  updateListValue,
};

const mapStateToProps = (state) => ({
  parameterProp: state.parameterProp,
  nowTime: state.nowTime,
  indexDevice: state.indexDevice,
  dataDevice: state.dataDevice,
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(Overview);
