import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GetNotif, SocketNotif } from '../api';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNotif: [],
    };
  }


  componentDidMount() {
    // GET notif first time
    GetNotif(((response) => {
      this.setState({
        listNotif: response.data,
      });
    }));

    // streaming notif
    SocketNotif(((response) => {
      const newNotif = this.state.listNotif;
      newNotif.push(response.data);
      this.setState({
        listNotif: newNotif,
      });
    }));
  }

  render() {
    const { listNotif } = this.state;
    const { parameterProp } = this.props;

    if (listNotif.length !== 0 && parameterProp) {
      listNotif.reverse();
      return (
        <div className="card-notif">
          <div className="title pt-2 pb-2 pl-3"><b>Peringatan</b></div>
          <div className="content">
            {
              listNotif.map((item) => (
                <div className="mb-3 ml-3 mr-3">
                  <div className="justify-content-between row mb-1">
                    <h6 className="text-capitalize"><b>{item.deviceInfo.name}</b></h6>
                    <h6 className="ml-4 text-darkGray">{new Date(item.createdAt).toLocaleString('en-GB')}</h6>
                  </div>
                  <h6 style={{ marginLeft: '-1rem' }} className="text-left"><b>{item.detail.message}</b></h6>
                  <div>
                    <div className="justify-content-between row mb-2">
                      <h6 className="text-darkGray">Nilai saat ini:</h6>
                      <h6>
                        <b>
                          {item.detail.sensor.val.toFixed(2)}
                          {' '}
                          {parameterProp[item.detail.sensorName]
                            ? parameterProp[item.detail.sensorName].unit : null}
                        </b>
                      </h6>
                    </div>
                    <div className="justify-content-between row ">
                      {
                        item.deviceInfo.deviceProp.name !== 'rain'
                          ? <h6 className="text-darkGray mb-2">Batas wajar:</h6>
                          : null
                      }
                      <h6>
                        <b>
                          {item.deviceInfo.deviceProp.name === 'temp' ? '5-35 °C'
                            : item.deviceInfo.deviceProp.name === 'winds' ? '<50 km/jam'
                              : null}
                        </b>
                      </h6>
                    </div>
                  </div>
                  <div style={{ borderBottom: 'solid 1px lightGray', marginLeft: '-1rem', marginRight: '-1rem' }} />
                </div>
              ))
            }
          </div>
        </div>
      );
    }
    return (<div className="card-notif p-3"><b>Tidak Ada Notifikasi</b></div>);
  }
}

const mapDispatchToProps = {

};

const mapStateToProps = (state) => ({
  listValue: state.listValue,
  parameterProp: state.parameterProp,
});


export default connect(
  mapStateToProps, mapDispatchToProps,
)(Notification);
