import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notification from '../component/Notification';
import { GetDevices } from '../api';
import {
  addParameterProp, addDeviceData, setIndexDevice,
} from '../redux/actions/actions';


// Get time in Indonesia
const LIST_HARI = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const LIST_BULAN = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

// list date
const TANGGAL = new Date().getDate();
const XHARI = new Date().getDay();
const XBULAN = new Date().getMonth();
const XTAHUN = new Date().getYear();

const HARI = LIST_HARI[XHARI];
const BULAN = LIST_BULAN[XBULAN];
const TAHUN = (XTAHUN < 1000) ? XTAHUN + 1900 : XTAHUN;

// list icon
const LOGO_PASANGKAYU = require('../assets/picture/logo-pasangkayu.png');
const ICON_CALENDAR = require('../assets/picture/icon/calendar.svg');
const ICON_CLOCK = require('../assets/picture/icon/clock.svg');
const ICON_NOTIF = require('../assets/picture/icon/notif.svg');
const ICON_MORE_BUTTON = require('../assets/picture/icon/more-button.svg');

// check screen
const isMobile = window.screen.width < '500';


// default index device
let indexDevices = 0;

// cycle map every 20 seconds
const CYCLE_MAP = 20000;

// Bagian Header
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showNotif: false,
    };
  }


  componentDidMount() {
    setInterval(this.selectDevice, CYCLE_MAP);

    // REST GET Devices (avoiding crash when refresh in every page)
    GetDevices(((response) => {
      const devices = response.data;

      const parameterProp = {
        temp: devices[0].parameterProp[0],
        hum: devices[0].parameterProp[1],
        press: devices[0].parameterProp[2],
        windd: devices[0].parameterProp[3],
        winds: devices[0].parameterProp[4],
        solar: devices[0].parameterProp[5],
        rain: devices[0].parameterProp[6],
      };

      // store parameter properties in redux
      this.props.addParameterProp(parameterProp);

      // store device data in redux
      this.props.addDeviceData(devices);
    }
    ));
  }

  selectDevice=() => {
    // set index device for cycle
    this.props.setIndexDevice(indexDevices);

    // counting index device
    if (indexDevices >= this.props.dataDevice.length - 1) {
      indexDevices = 0;
    } else {
      indexDevices += 1;
    }
  }

  // show menu user manajemen and logout
  showMenu=() => {
    this.setState({
      showNotif: false,
      showMenu: !this.state.showMenu,
    });
  }

  changePage=() => {
    this.hidePopup();
    // move page
    this.props.history.push('user-management');
  }

  hidePopup= () => {
    this.setState({
      showNotif: false,
      showMenu: false,
    });
  }

  render() {
    const { showMenu, showNotif } = this.state;
    const { nowTime } = this.props;
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light header justify-content-between sticky-top">
        <div className="row">
          <img height="50" alt="Logo Pasangkayu" className="ml-2" src={LOGO_PASANGKAYU} />
          <h5 className="text-darkGray mr-5 ml-3 mt-auto mb-auto">
            {' '}
            <b>Pasangkayu</b>
            {' '}
          </h5>
          {
            window.location.pathname !== '/overview' && isMobile === false
              ? (
                <div className="row ml-4">
                  <NavLink className="mt-auto mb-auto" to="/overview" style={{ textDecoration: 'none' }}><h3 className="text-darkGray mr-3"> &larr;</h3></NavLink>
                  <NavLink className="mt-auto mb-auto" to="/overview" style={{ textDecoration: 'none' }}><h4 className="text-darkGray mr-3" style={{ cursor: 'pointer' }}> Overview </h4></NavLink>
                </div>
              )
              : null
          }
        </div>

        <div className="row" id="navbarSupportedContent">
          <div className="row collapse navbar-collapse">
            <img src={ICON_CLOCK} />
            <h5 className="text-darkGray mr-5 ml-2 mt-auto mb-auto navbar-nav">
              {' '}
              {(`0${new Date(nowTime).getHours()}`).slice(-2)}
  :
              {(`0${new Date(nowTime).getMinutes()}`).slice(-2)}
            </h5>
            <img alt="icon-calendar" src={ICON_CALENDAR} />
            <h5 className="text-darkGray mr-5 ml-2 mt-auto mb-auto">
              {' '}
              {HARI}
  ,
              {' '}
              {TANGGAL}
              {' '}
              {BULAN}
              {' '}
              {TAHUN}
            </h5>
          </div>
          <div className="mr-5 mt-auto mb-auto collapse navbar-collapse" onClick={() => this.setState({ showMenu: false, showNotif: !showNotif })}>
            <img alt="Notifikasi" src={ICON_NOTIF} style={{ cursor: 'pointer' }} />
            {showNotif ? <Notification /> : null}
          </div>
          <div className="mr-3">
            <input onClick={this.showMenu} type="image" src={ICON_MORE_BUTTON} alt="Dropdown Menu" width="48" height="48" />
            {
              showMenu
                ? (
                  <div className="card-menu">
                    {isMobile
                      ? (
                        <div>
                          <button
                            type="button"
                            onClick={() => this.props.history.push('overview')}
                            className="btn btn-lowGray btn btn-lowGray"
                          >
Overview
                          </button>
                        </div>
                      )
                      : null}
                    {localStorage.role === 'admin'
                      ? <button type="button" onClick={this.changePage} className="btn btn-lowGray btn btn-lowGray">Manajemen User</button> : null}
                    <div><a href="/logout" className="btn text-danger">Logout</a></div>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </nav>
    );
  }
}


const mapDispatchToProps = {
  addDeviceData,
  addParameterProp,
  setIndexDevice,
};

const mapStateToProps = (state) => ({
  nowTime: state.nowTime,
  dataDevice: state.dataDevice,
});


export default withRouter(connect(
  mapStateToProps, mapDispatchToProps,
)(Header));
